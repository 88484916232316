<template>
  <div>
    <vs-card>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-table search :data="users">
            <template slot="header">
              <h3>
                My Leaves
              </h3>
            </template>
            <template slot="thead">
              <vs-th sort-key="leave_from_date" style="background: #f3f3f3;"
                >Submitted On</vs-th
              >
              <vs-th sort-key="leave_from_date">From</vs-th>
              <vs-th sort-key="leave_to_date">To</vs-th>
              <vs-th sort-key="leave_reason">Reason</vs-th>
              <vs-th sort-key="status">Status</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td
                  :data="data[indextr].appliedOn"
                  style="background: #f3f3f3;"
                >
                  {{ formatDate(data[indextr].created_at) }}
                </vs-td>
                <vs-td>
                  {{ unixTimestampConvert(tr.leave_from_date) }}
                </vs-td>
                <vs-td>
                  {{ unixTimestampConvert(data[indextr].leave_to_date) }}
                </vs-td>
                <vs-td :data="data[indextr].leave_reason" style="width: 50%;">
                  {{ data[indextr].leave_reason }}
                </vs-td>
                <vs-td :data="data[indextr].status">
                  <div
                    v-if="data[indextr].status === 'approved'"
                    style="text-align: center; "
                  >
                    <vs-chip color="success">Approved</vs-chip>
                  </div>
                  <div
                    v-if="data[indextr].status === 'rejected'"
                    style="text-align: center; "
                  >
                    <vs-chip color="danger">Rejected</vs-chip>
                  </div>
                  <div
                    v-if="data[indextr].status === 'pending'"
                    style="text-align: center; "
                  >
                    <vs-chip color="warning">Pending</vs-chip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
    </vs-card>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
require("vue-simple-calendar/static/css/default.css");
// import router from "@/router";
import moment from "moment";
import axios from "axios";
import constants from "../../constants.json";

import Datepicker from "vuejs-datepicker";
export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker,
  },
  data() {
    return {
      users: [],
    };
  },
  watch: {},

  computed: {},
  mounted() {
    this.getLeaves();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    getLeaves() {
      let url = `${constants.MILES_CM_BACK}leave`;
      // this.uploadBoolean = false;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("get leaves list", response.data.data);
          // let leavesData = response.data.data;
          this.users = response.data.data;
          // leavesData.forEach((element) => {
          //   let getleaveappliedDate = moment(element.created_at).format();

          //   this.users.push({
          //     user_id: element.user_id,
          //     team_id: element.team_id,
          //     appliedOn: moment(getleaveappliedDate).format("ll"),
          //     leave_from_date: moment(element.leave_from_date).format("ll"),
          //     leave_to_date: moment(element.leave_to_date).format("ll"),
          //     leave_reason: element.leave_reason,
          //     status: element.status,
          //   });
          // });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
<style>
.vs-con-table {
  width: 100%;
}
th .vs-table-text {
  /* justify-content: center; */
}
/* td.td.vs-table--td {
    text-align: center;
} */
</style>
